.chosen-container {
  @apply text-base flex-grow #{!important};
}

.chosen-choices {
  @apply rounded border-gray-300 p-2 #{!important};
}


.form-input, .form-select, .form-checkbox, .form-textarea {
  @apply rounded p-2 #{!important};
}

form label {
  @apply text-gray-500;
}