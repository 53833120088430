button {
  .show_when_enabled { display: flex; }
  .show_when_disabled { display: none; }

  &[disabled] {
    .show_when_enabled { display: none; }
    .show_when_disabled { display: flex; }
  }

}

button.swal2-confirm.swal2-styled {
  @apply bg-blue-600;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
  @apply ring-blue-600 ring-2 outline-offset-2
}

button:focus {
  @apply outline-none;
}

.btn {
  @apply inline-flex items-center font-medium py-2 px-4 rounded transition ease-in-out duration-150;
  @apply border border-gray-300 text-gray-700 bg-white;
}

.btn:hover {
  @apply text-gray-500;
}

.btn:focus {
  @apply outline-none border-blue-300 ring;
}

.btn:active {
  @apply text-gray-800 bg-gray-50;
}

.btn:disabled {
  @apply opacity-50 cursor-not-allowed;
}

/* Primary & Secondary */

.btn-primary {
  @apply btn;
  @apply border border-opacity-0 text-white bg-blue-600;
}

.btn-primary:hover {
  @apply bg-blue-700 text-gray-50;
}

.btn-primary:focus {
  @apply outline-none border-blue-700 ring;
}

.btn-primary:active {
  @apply bg-blue-700;
}

.btn-secondary {
  @apply btn;
  @apply border border-opacity-0 text-blue-700 bg-blue-100;
}

.btn-secondary:hover {
  @apply bg-blue-50;
}

.btn-secondary:focus {
  @apply outline-none border-blue-300 ring;
}

.btn-secondary:active {
  @apply bg-blue-200;
}

.btn-red {
  @apply btn;
  @apply border border-opacity-0 text-white bg-red-600;
}

.btn-red:hover {
  @apply bg-red-500;
}

.btn-red:focus {
  @apply outline-none border-blue-700 ring;
}

.btn-red:active {
  @apply bg-red-700;
}

.btn-green {
  @apply btn;
  @apply border border-opacity-0 text-white bg-green-600;
}

.btn-green:hover {
  @apply bg-green-500;
}

.btn-green:focus {
  @apply outline-none border-blue-700 ring;
}

.btn-green:active {
  @apply bg-green-700;
}

.btn-disabled {
  @apply bg-gray-600 text-white border-gray-600 cursor-not-allowed;
}

.btn-primary:disabled,
.btn-secondary:disabled,
.btn-cta-next:disabled,
.btn-cta-prev.disabled {
  @apply btn-disabled;
}

button:disabled {
  @apply text-gray-600 cursor-not-allowed;
}


.btn-primary:disabled:hover,
.btn-primary.disabled:hover,
.btn-secondary:disabled:hover,
.btn-secondary.disabled:hover,
.btn-cta-next:disabled:hover,
.btn-cta-next.disabled:hover,
.btn-cta-prev:disabled:hover,
.btn-cta-prev.disabled:hover {
  box-shadow: none;
}