.adx2logosmall {
  top: 0;
  left: 0;
  width: 142px;
  height: 32px;
  background: url(/app/assets/images/adx20small3.png);
  background-size: contain;
}

.adx2logosmallwhite {
  top: 0;
  left: 0;
  width: 188px;
  height: 32px;
  background: url(/app/assets/images/adx20small3white.png) center no-repeat;
}